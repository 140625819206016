import React from "react";
import BlueskyProfile from "./components/BlueSkyProfile";

function App() {
  return (
    <div>
      <BlueskyProfile />
    </div>
  );
}

export default App;
